import React, {useState, useEffect} from 'react';
import { DataGrid, GridToolbar,  GridToolbarFilterButton,  GridColDef, GridColumnGroupingModel, arSD} from '@mui/x-data-grid';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import './App.css';

import axios from 'axios';
export default function App() {
   const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 5
  })

  useEffect(() => {
    const fetchData = async () => {
      console.log('ON')
      setPageState(old => ({ ...old, isLoading: true }))
      const response = await fetch(`https://demo3.devcmitexpert.com/getData.php?page=${pageState.page}&limit=${pageState.pageSize}`);

      const json = await response.json();
      setPageState(old => ({ ...old, isLoading: false, data: json.data, total: json.total }))
    }
    fetchData()
  }, [pageState.page, pageState.pageSize])



  const columns: GridColDef[] = [

    { field: "building_no", headerName: "Building", width: 100, headerClassName: 'tbl-group-head', headerAlign: 'center'
    },
    { field: "unit_no", headerName: "Unit No", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center',

    },
    { field: "floor_no", headerName: "Floor No", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center' },
    { field: "sqm", headerName: "SQM", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center' },


    { field: "price_1", headerName: "Price 1", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center' },
    { field: "price_2", headerName: "Price 2", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center' },
    { field: "price_3", headerName: "Price 3", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center' },

    { field: "title_2", headerName: "Title", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center' },
    { field: "title_3", headerName: "Title", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center' },
    { field: "title_4", headerName: "Title", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center' },
    { field: "title_5", headerName: "Title", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center' },
    { field: "sum", headerName: "Sum", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center' },



    { field: "date", headerName: "Date", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center' },
    { field: "customer_name", headerName: "Name", width: 150, headerClassName: 'tbl-group-head',   headerAlign: 'left' },
    { field: "name_title", headerName: "Title", width: 10, headerClassName: 'tbl-group-head',   headerAlign: 'center' },


    
    { field: "date_1", headerName: "Date", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center' },

    { field: "customer_name_1", headerName: "Name", width: 150, headerClassName: 'tbl-group-head',   headerAlign: 'center' },
    { field: "email", headerName: "Email", width: 150, headerClassName: 'tbl-group-head',   headerAlign: 'center' },
    
    { field: "title_1", headerName: "Title", width: 100, headerClassName: 'tbl-group-head',   headerAlign: 'center' },
    
  ];
 


  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'Main Title 1',
      description: '',
      headerClassName: 'tbl-group',
      children: [{ field: 'building_no'},{ field: 'unit_no'},{ field: 'floor_no' }, {field: 'sqm'}],
     
    },
    {
      groupId: 'Main Title 2',
      description: '',
      headerClassName: 'tbl-group',
      children: [{ field: 'price_1'}, { field: 'price_2'}, { field: 'price_3'}],
    },
    {
      groupId: 'Main Title 3',
      description: '',
      headerClassName: 'tbl-group',
      children: [{ field: 'title_2'}, { field: 'title_3'}, { field: 'title_4'}, { field: 'title_5'}, { field: 'sum'}],
    },

    {
      groupId: 'Main Title 4',
      description: '',
      headerClassName: 'tbl-group',
      children: [{ field: 'date'}, { field: 'customer_name'}, { field: 'name_title'}],
    },

    {
      groupId: 'Main Title 5',
      description: '',
      headerClassName: 'tbl-group',
      children: [{ field: 'date_1'}, { field: 'customer_name_1'}, { field: 'email'}, { field: 'title_1'}],
    },

  ];

  const existingTheme = useTheme();
  const theme = React.useMemo(
    () =>
      createTheme({}, existingTheme, {
        direction: 'rtl',
      }),
    [existingTheme],
  );

  return(

     <Box
      sx={{
        height: 300,
        width: '100%',
        '& .unit_no_colored': {
          backgroundColor: '#dadada',
          fontWeight: '700'
        },
        '& .price_2_color': {
          backgroundColor: '#ffff33',
          fontWeight: '700'
        },
        '& .bold-border': {
          borderLeft: '1px solid #000'
        }
      }}
    >

    <ThemeProvider theme={theme}>
    <div dir="rtl" style={{ height: '600px', width: '100%' }}>
      <div style={{ display: 'flex', height: '100%' }}>
        <div>
          <DataGrid
            autoHeight
            rows={pageState.data}
            rowCount={pageState.total}
            loading={pageState.isLoading}
            pageSizeOptions={[5, 10, 25]}
            paginationMode="server"
            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 5 } } }}
            onPaginationModelChange={(pageInfo) =>{ console.log('clicked', pageInfo); setPageState(old => ({ ...old, page: pageInfo.page, pageSize: pageInfo.pageSize }))}}
            columns={columns}
            disableDensitySelector
            disableExportsSelector
            slots={{ toolbar: GridToolbar }}
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroupingModel}
            slotProps={{
              toolbar: {
                  printOptions: { disableToolbarButton: true },
                  csvOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                }
            }}
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#ffff33',
              },
              
            }}
            getCellClassName={(params) => {
              if (params.field === 'unit_no') {
                return 'unit_no_colored';
              }
              /*if (params.field === 'price_2') {
                return 'price_2_color';
              }*/
              if (params.field === 'sqm' || params.field === 'price_3' || params.field === 'sum' || params.field === 'name_title')
              {
                return 'bold-border';
              }
            }}
          />
        </div>
      </div>
    </div>
    </ThemeProvider>
    </Box>
  );
}